import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import ImageUploading from "react-images-uploading";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DeleteIcon from '@mui/icons-material/Delete';


const ImageUploadingInput = ({ images, onImageChange }) => {
	const [imageArray, imageArraySet] = React.useState(images);
	const maxNumber = 10;
    const maxMbFileSize = 2 * 1024 * 1024; 
	const onChange = (imageList, addUpdateIndex) => {
		// data for submit
		// console.log(imageList, addUpdateIndex);
		imageArraySet(imageList);
        onImageChange(imageList)
	};

    React.useEffect(() => {
        imageArraySet(images);
    }, [images])

	return <>
     <Box>
        {/* <Button onClick={()=>console.log(images)}>CLICK</Button> */}
        <ImageUploading multiple value={imageArray} onChange={onChange} maxFileSize={maxMbFileSize} maxNumber={maxNumber} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "webp"]}>
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps,errors }) => (
                // write your building UI
                
                
                <Box className="">
                    {/* <Button onClick={()=>console.log(errors)}>error</Button> */}
                    <Box mb={2} style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                        <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}
                        type="button"
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                        
                        >
                        Click or Drop Images here
                        </Button>
                        &nbsp;
                        {imageList.length > 0 && <Button startIcon={<DeleteIcon />} color="secondary" type="button" onClick={onImageRemoveAll}>Remove all images</Button>}
                    </Box>
                    
                    <Grid container spacing={2}>
                    {imageList.map((image, index) => (
                        <Grid item key={index} className="image-item">
                            <Card sx={{ maxWidth: 300 }}>
                                <CardMedia
                                    sx={{ height: 100, width: 100 }}
                                    image={image.data_url?image.data_url:`${process.env.REACT_APP_API_URL_COMMODITY}/${image.url}`}
                                  
                                />

                                <CardActions>
                                <IconButton size="small" variant="outlined" type="button" onClick={() => onImageUpdate(index)}><SwapHorizIcon /></IconButton>
                                <IconButton size="small" variant="outlined" type="button" color='secondary' onClick={() => onImageRemove(index)}><DeleteIcon /></IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                    </Grid>
                    <Box style={{marginTop:5}}>
       {/* {errors && errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}  */}
       {errors && errors.maxNumber && <Typography color="error">max number allowed is {maxNumber}</Typography>}
       {errors && errors.acceptType && <span>Your selected file type is not allow</span>} 
      {(errors && errors.maxFileSize && <Typography color="error">Selected file size exceed 5 MB</Typography>)}
      {/* {errors.resolution && <span>Selected file is not match your desired resolution</span>}  */}
    </Box>
                </Box>
            )}
            
        </ImageUploading>
    </Box>
    </>
};

export default ImageUploadingInput;
