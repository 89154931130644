import CryptoJS from 'crypto-js'
import { config } from './config'
import { useRef, useEffect } from 'react'
import axiosBase from './api/base';
import axios1 from './api/axios1';
import dateformat from "dateformat";
import Emitter from './services/emitter'

// // Create Base64 Object
// var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/++[++^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

/**
*
*  Base64 encode / decode
*  http://www.webtoolkit.info/
*
**/
var Base64 = {

    // private property
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    // public method for encoding
    encode: function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },

    // public method for decoding
    decode: function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        //eslint-disable-next-line
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },



    // private method for UTF-8 encoding
    _utf8_encode: function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    // private method for UTF-8 decoding
    _utf8_decode: function (utftext) {
        var string = "";
        var i = 0;
        var c = 0
        //var c1 = 0
        var c2 = 0;
        var c3 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }

}

function baseName(str) {
    var base = new String(str).substring(str.lastIndexOf('/') + 1);
    if (base.lastIndexOf(".") != -1)
        base = base.substring(0, base.lastIndexOf("."));
    return base;
}

export function LSQSessionTrack(user) {
    var now = new Date();
    //var now_utc = new Date(now.toUTCString().slice(0, -4));

    if (localStorage.getItem('LSQ_SESSION_KEY') === null) {
        localStorage.setItem('LSQ_SESSION_KEY', 1)
        const data = {
            // u: 'http://13.127.105.228/api/v1/activity/updateTimeStampActivity',
            u: 'https://nodeprod.coalshastra.com/api/v1/activity/updateTimeStampActivity',
            email: user.email,
            operating_system: "2",
            user_id: user.id,
            timestamp: dateformat(now, 'yyyy-mm-dd HH:MM:ss')
        }

        axios1.post(`${config.api_url}/lsqproxy`, data)
            .then(e => {
                if (e.data && e.data.data && e.data.data.Status && e.data.data.Status.toLowerCase() === 'success') {
                    console.log("LSQ session track success")
                }
                else {
                    console.log("LSQ session track failed")
                }
            })
            .catch(error => {
                console.error("LSQ session track error")
                console.error(error)
            })
    }
}

/**
 * Console admin user roles array function
 *
 * @return {array}
 * @example
 * ...
 * import { consoleRoles } from '../helpers'
 * ...
 *
 * var roles = consoleRoles()
 */
export function consoleRoles() {
    return [
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Dealer' },
        { id: 3, name: 'Marketing' },
    ]
}

export function whatsappSharingUrl(text) {
    return "https://web.whatsapp.com/send?text=" + text
}


export const sortAlgorithm = (a, b) => a < b ? 1 : -1;
export const timeAgo = (timestamp) => {
    const currentDate = new Date();
    const previousDate = new Date(timestamp);
    const timeDifference = currentDate - previousDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
};

export const filterAndSearchAlgorithm = (term, data) => {
    return (data ? data : '').toLowerCase().indexOf(term ? term.toLowerCase() : '') !== -1;
}

/**
 * Function to check if the given user has a role
 * @param {string} roleName Role name to check
 * @func
 * @name consoleUserHasRole
 *
 * @return {boolean}
 *
 * @example
 * ...
 * import { consoleUserHasRole } from '../helpers'
 * ...
 *
 * if(consoleUserHasRole(consoleUser, 'admin))
 * {
 *     ...
 * }
 */
export function consoleUserHasRole(consoleUserRoles, role_id) {
    if (consoleUserRoles.includes(role_id)) {
        return true
    }

    return false
}

export function nameJson(nameString) {
    var nameArr = nameString.split(" ")
    var name = { firstName: nameArr[0], lastName: ((nameArr.length >= 2) ? nameArr[nameArr.length - 1] : '') }

    return name
}

/**
 * Function to get url of Console/Admin User
 * by role_id
 *
 * @function
 * @name consoleUserDashboard
 * @global
 * @param {int} role_id Admin user role id
 * @returns {string}
 *
 * @example
 * ...
 * import { consoleUserDashboard } from '../helpers'
 * ...
 *
 * consoleUserDashboard(consoleUser.role_id)
 */
export function consoleUserDashboard(role_id) {
    let url = '/console/admin'
    return url;
    switch (role_id) {
        case 1:
            url = '/console/admin'
            break;
        case 2:
            url = '/console/dealer'
            break;
        case 3:
            url = '/console/marketing'
            break;
        default:
            break;
    }

    return url
}

export function cEncrypt(data) {
    //console.log('encrypt ', data)
    // data = data.toString()
    // const enc = CryptoJS.Rabbit.encrypt(data, config.crypto_key)
    return btoa(data)
}

export function cDecrypt(data) {
    //console.log('decrypt ', data)
    // var bytes = CryptoJS.Rabbit.decrypt(data, config.crypto_key);
    // const dec = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(dec)
    return atob(data)
}

export function mutualCount(a, b, c) {
    const res = a.filter(x => b.includes(x)).concat(b.filter(x => a.includes(x)));
    const finalArray = Array.from(new Set(res));

    //first method by map method
    // const mutualRefCount = finalArray.length;
    // const result = c.map(x => (x.min <= mutualRefCount && x.max >= mutualRefCount) && x.value  )
    // return result;

    //second method by for loop
    let value = 0;
    for (let i = 0; i < c.length; i++) {
        if (c[i]?.min <= finalArray?.length && c[i]?.max >= finalArray?.length) {
            value = c[i]?.value
        }
    }
    return value;
}

// Limit text
export function limitText(text, limit = 35) {
    return text.length > 20 ? `${text.substr(0, limit)}...` : text;
}


export function calculatePriceRange(priceArr, priceRangeCap) {
    let min = 0
    let max = 0
    let avg = 0

    if (priceArr.length > 0) {
        priceArr = priceArr.map(v => parseInt(v))

        min = max = avg = Math.round(((priceArr.reduce((t, x) => t + x)) / priceArr.length) * 100) / 100
        // min = Math.min(...priceArr)
        // max = Math.max(...priceArr)
        min -= Math.round((avg * priceRangeCap || 0.25) * 100) / 100
        max += Math.round((avg * priceRangeCap || 0.25) * 100) / 100
    }

    return { min: min, max: max, avg: avg }
}

export function convertNumberToWords(amount, currency) {
    var words = [];
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = [];
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        let value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string + currency + " Only.";
}
export function temp__convertNumberToWords(amount) {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
        dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
        tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
        handle_tens = function (dgt, prevDgt) {
            return 0 === dgt ? "" : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt])
        },
        handle_utlc = function (dgt, nxtDgt, denom) {
            return (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") + (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
        };

    var str = "",
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words = [];
    if (amount += "", isNaN(parseInt(amount))) str = "";
    else if (parseInt(amount) > 0 && amount.length <= 10) {
        for (digitIdx = amount.length - 1; digitIdx >= 0; digitIdx--) switch (digit = amount[digitIdx] - 0, nxtDigit = digitIdx > 0 ? amount[digitIdx - 1] - 0 : 0, amount.length - digitIdx - 1) {
            case 0:
                words.push(handle_utlc(digit, nxtDigit, ""));
                break;
            case 1:
                words.push(handle_tens(digit, amount[digitIdx + 1]));
                break;
            case 2:
                words.push(0 !== digit ? " " + sglDigit[digit] + " Hundred" + (0 !== amount[digitIdx + 1] && 0 !== amount[digitIdx + 2] ? " " : "") : "");
                break;
            case 3:
                words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                break;
            case 4:
                words.push(handle_tens(digit, amount[digitIdx + 1]));
                break;
            case 5:
                words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                break;
            case 6:
                words.push(handle_tens(digit, amount[digitIdx + 1]));
                break;
            case 7:
                words.push(handle_utlc(digit, nxtDigit, "Crore"));
                break;
            case 8:
                words.push(handle_tens(digit, amount[digitIdx + 1]));
                break;
            case 9:
                words.push(0 !== digit ? " " + sglDigit[digit] + " Hundred" + (0 !== amount[digitIdx + 1] || 0 !== amount[digitIdx + 2] ? "" : " Crore") : "")
        }
        str = words.reverse().join("")
    } else str = "";
    return str + "Rupees Only"

}


export function IND_money_format(amount) {
    var x = amount;
    x = x.toString();
    var afterPoint = '';
    if (x.indexOf('.') > 0)
        afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res;
}

export function getUserTokenKey(userType) {

    if (userType == 'console')
        return 'console_user_token'

    return 'user_token'
}

export function getCommUserKey(userType) {
    if (userType == 'commconsole')
        return 'comm_console_user_token'

    return 'comm_user_token'
}


export function storeUserToken(userType, token) {
    const userTokenItemKey = getCommUserKey(userType)
    localStorage.setItem(userTokenItemKey, CryptoJS.AES.encrypt(token, config.crypto_key))
}

export function storeCommuserToken(userType, token) {
    const CommuserTokenItemKey = getCommUserKey(userType)

    localStorage.setItem(CommuserTokenItemKey, CryptoJS.AES.encrypt(token, config.crypto_key))
}

export function getUserToken(userType) {
    const userTokenItemKey = getUserTokenKey(userType)

    const stored_user_token = localStorage.getItem(userTokenItemKey)
    if (stored_user_token) {
        var bytes = CryptoJS.AES.decrypt(stored_user_token.toString(), config.crypto_key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null
}
export function getCommUserToken(key) {

    const CommuserTokenItemKey = getCommUserKey(key)

    const stored_user_token = localStorage.getItem(CommuserTokenItemKey)
    if (stored_user_token) {
        var bytes = CryptoJS.AES.decrypt(stored_user_token.toString(), config.crypto_key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null
}

export function getAdminCommUserToken(userType) {

    const CommuserTokenItemKey = getCommUserKey(userType)

    const stored_user_token = localStorage.getItem(CommuserTokenItemKey)
    if (stored_user_token) {
        var bytes = CryptoJS.AES.decrypt(stored_user_token.toString(), config.crypto_key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null
}

export function camelToNormalCase(string) {
    return string.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) { return str.toUpperCase(); });
}

export function humanizeString(str) {
    var frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

export function getPartnerListKeys() {
    return {
        role_name: "",
        role_mob_num: "",
        role_lan_num: "",
        role_mail: ""
    }
}

export function getPartnerListLabel(key) {
    switch (key) {
        case 'role_name':
            return 'Name'

        case 'role_mob_num':
            return 'Mobile Number'

        case 'role_lan_num':
            return 'Landline Number'

        case 'role_mail':
            return 'Email'

        default:
            return ''
    }
}

export function validatorRegEx(type, ...args) {
    switch (type) {
        case 'alpha_no_space':
            return '^[A-Za-z]+$'

        case 'alpha_numerics_but_not_only_numerics':
            // return '^[a-zA-Z0-9 ]*$' //alphanumeric with spaces
            return '^(?![0-9]*$)[a-zA-Z0-9 ]+$'

        case 'alpha_with_space':
            return '^[a-zA-Z ]*$'

        case 'alpha_with_space_uppercase':
            return '^[A-Z ]*$'

        case 'alpha_with_space_fullStop_comma':
            //eslint-disable-next-line
            return '^[a-zA-Z,.+0-9\n\-# ]*$'
        // return '^[a-zA-Z,.{0-9}\\- ]*$'


        case 'alphaNum_with_space_fullStop_comma_dash_slash':
            //eslint-disable-next-line
            return '^[a-zA-Z,-.+0-9\n\-# /]*$'


        case 'mobile':
            return '^((?!(0))[0-9]{10})$'

        case 'mobile12':
            return '^((?!(0))[0-9]{12})$'

        case 'nonZero':
            return '^[1-9][0-9]*$'

        case 'numberWithTwoDecimal':
            //eslint-disable-next-line
            return '^[0-9]+\.?([0-9]{2})*$'

        case 'image_validation':
            //eslint-disable-next-line
            return '(.*?)\.(jpg|gif|doc|pdf)$'

        case 'vehicleNumber':
            // return '^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$'
            return ""

        // case 'lowercase':
        //     return "^/[a-z]/$"

        case 'email':
            return "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,6}$"

        //return "^([a-z0-9_\.]+)@([a-z0-9_\.]+)\.([a-z]{2,6})$"


        // return "^(('[\\a-z0-9_\.\\s]+')|([\\a-z0-9_\.]+(?:\\.[\\a-z0-9_\.]+)*)|('[\\a-z0-9_\.\\s]+')([\\a-z0-9_\.]+(?:\\.[\\a-z0-9_\.]+)*))(@((?:[\\a-z0-9_\.]+\\.)*\\w[\\a-z0-9_\.]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$)|(@\\[?((25[0-5]\\.|2[0-4][0-9]\\.|1[0-9]{2}\\.|[0-9]{1,2}\\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\]?$)"


        case 'numeric':
            return "^\\d{0,9}$"

        case 'gstin':
            // return "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
            return "^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$"

        case 'pan':
            return "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$"

        case 'pin_code':
            return "^[1-9][0-9]{5}$"

        case 'url':
            //eslint-disable-next-line
            return "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"

        case 'endsWith': {
            //eslint-disable-next-line
            let endsWith = args[0];
            //console.log(args)

            if (Array.isArray(args[0])) {
                endsWith = args[0].join('|');
            }
            return `^.*(${endsWith})$`
        }

        case 'multiple25':
            return "^\\d{0,17}(25|50|75|00)$"

        default:
            break;
    }
}

export function numDiffRule(field1Val, field2Val, field1, field2, allowedDiff) {
    return {
        numDiff: {
            test: (field1Val, field2Val) => (field1Val - field2Val) <= allowedDiff,
            message: (field1, field2) => `Difference between ${field1} and ${field2} should be max ${allowedDiff}`,
        }
    }
}


export function humanizeWord(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

export function getDecryptedLocalItem(itemName) {
    const data = localStorage.getItem(itemName)
    //let user = {}
    //let isLoggedIn = false
    if (data) {
        var bytes = CryptoJS.AES.decrypt(data.toString(), config.crypto_key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null
}

export function alphabetsOnly(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function userCan(user, feature) {
    if (user.parent_user == 1)
        return true

    return userHasFeature(feature, user.features)
}

export function userHasFeature(featureName, features) {
    if (!Array.isArray(features))
        return false

    var filtered = features.filter(feature => {
        return feature.name == featureName
    })

    return !!(filtered.length > 0)
}

export function makeQueryString(params) {
    var queryString = '';
    if (params) {
        queryString = '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    return queryString;
}

export function base64SharingLink(url, params) {
    return url + "?k=" + btoa(Object.keys(params).map(key => key + '=' + params[key]).join('&'));
}


export function GCVList() {
    return [
        { id: 1, name: "GAR", detail: 'As Received (GAR)' },
        { id: 2, name: "GAD/GCV", detail: "Air Dried (GAD)" },
        { id: 3, name: "NAR", detail: "As Received (NAR)" }
    ]
}

export function GradeList(value) {
    if (value > 7000) {
        return { id: 1, name: "G-1", detail: 'Exceeding 7000' }
    } else if (value > 6700 || value < 7000) {
        return { id: 2, name: "G-2", detail: "Exceeding 6700 and not exceeding 7000" }
    } else if (value > 6400 || value < 6700) {
        return { id: 3, name: "G-3", detail: "Exceeding 6400 and not exceeding 6700" }
    } else if (value > 6100 || value < 6400) {
        return { id: 4, name: "G-4", detail: "Exceeding 6100 and not exceeding 6400" }
    } else if (value > 5800 || value < 6100) {
        return { id: 5, name: "G-5", detail: "Exceeding 5800 and not exceeding 6100" }
    } else if (value > 5500 || value < 5800) {
        return { id: 6, name: "G-6", detail: "Exceeding 5500 and not exceeding 5800" }
    } else if (value > 5200 || value < 5500) {
        return { id: 7, name: "G-7", detail: "Exceeding 5200 and not exceeding 5500" }
    } else if (value > 4900 || value < 5200) {
        return { id: 8, name: "G-8", detail: "Exceeding 4900 and not exceeding 5200" }
    } else if (value > 4600 || value < 4900) {
        return { id: 9, name: "G-9", detail: "Exceeding 4600 and not exceeding 4900" }
    } else if (value > 4300 || value < 4600) {
        return { id: 10, name: "G-10", detail: "Exceeding 4300 and not exceeding 4600" }
    } else if (value > 4000 || value < 4300) {
        return { id: 11, name: "G-11", detail: "Exceeding 4000 and not exceeding 4300" }
    } else if (value > 3700 || value < 4000) {
        return { id: 12, name: "G-12", detail: "Exceeding 3700 and not exceeding 4000" }
    } else if (value > 3400 || value < 3700) {
        return { id: 13, name: "G-13", detail: "Exceeding 3400 and not exceeding 3700" }
    } else if (value > 3100 || value < 3400) {
        return { id: 14, name: "G-14", detail: "Exceeding 3100 and not exceeding 3400" }
    } else if (value > 2800 || value < 3100) {
        return { id: 15, name: "G-15", detail: "Exceeding 2800 and not exceeding 3100" }
    } else if (value > 2500 || value < 2800) {
        return { id: 16, name: "G-16", detail: "Exceeding 2500 and not exceeding 2800" }
    } else if (value > 2200 || value < 2500) {
        return { id: 17, name: "G-17", detail: "Exceeding 2200 and not exceeding 2500" }
    }
}

export function storeCTAClick(e, type) {
    const actionTypes = [
        'app-home-button',
        'app-product-requirements-button',
        'app-add-product-requirements-button-homepage',
        'app-add-product-requirements-button-bottom-navigation',
        'app-vessel-button',
        'app-analytics-button',
        'app-whatsapp-sharing-vessel-listing-badges',
        'app-notification-bell-icon',
        'app-tickets-raised-via-help-center',
        'app-add-product-on-bid-cta',
        'app-add-requirement-on-offer-cta',
        'app-add-product-requirement-top-navigation',
        'app-list-of-product-requirement-top-navigation',
        'app-all-vessel-list-top-navigation'
    ]

    var foundIndex = actionTypes.indexOf('app-' + type)

    // if (foundIndex > -1) {
    //     axiosBase.post(`${config.api_url}/save-app-usage-stats`, {
    //         route_named: actionTypes[foundIndex],
    //         platform_type: 2
    //     })
    //         .catch(error => {
    //             console.error(error)
    //         })
    // }


}


export function getDeliveryTermName(deliveryTermId, origin, coalTypeId, currency) {
    if (currency == 'INR') {
        if (deliveryTermId == 1 && origin == 1 && coalTypeId == 5) {
            return 'Yard delivered'
        }

        if (deliveryTermId == 2 && origin == 1 && coalTypeId == 5) {
            return 'FOB (free on board)'
        }

        if (deliveryTermId == 1 && origin == 1 && coalTypeId != 5) {
            return 'Factory Delivered'
        }

        if (deliveryTermId == 2 && origin == 1 && coalTypeId != 5) {
            return 'Ex-mine/depo'
        }

        if (deliveryTermId == 1 && origin == 2) {
            return 'Factory Delivered'
        }

        if (deliveryTermId == 2 && origin == 2) {
            return 'Ex-port'
        }

        return ""
    } else {
        if (deliveryTermId == 2 && origin == 2) {
            return ' Port of Discharge';
        }

        if (deliveryTermId == 1 && origin == 2) {
            return 'Port of Loading';
        }
        if (deliveryTermId == 2 && origin == 1) {
            return ' Port of Discharge';
        }

        if (deliveryTermId == 1 && origin == 1) {
            return 'Port of Loading';
        }
        return '';
    }
}

export function searchInJson(keyword, arr) {

    keyword = keyword.trim()
    if (keyword.length == 0) {
        return arr
    }

    let filtered = []

    const check = (str, val) => {
        let f = false
        if (typeof val === 'string' && val.toLowerCase().includes(str.toLowerCase())) {
            f = true
        }

        if (typeof val === 'number' && val.toString().includes(str.toString())) {
            f = true
        }

        if (typeof val === 'object' && JSON.stringify(val).toLowerCase().includes(str.toLowerCase())) {
            f = true
        }
        return f
    }

    for (let i = 0; i < arr.length; i++) {
        let found = false
        for (const [key, objOuter] of Object.entries(arr[i])) {
// console.log('objOuter',objOuter)
            if (objOuter && typeof objOuter === 'object') {

                for (const [keyInner, objInner] of Object.entries(objOuter)) {
                    // console.log('keyInner',keyInner)
                    // console.log('objInner',objInner)
                    if (check(keyword, objInner)) {
                        found = true
                        break
                    }
                }
            }
            else {
                if (check(keyword, objOuter)) {
                    found = true
                }
            }
        }

        if (found) {
            filtered.push(arr[i])
        }
    }
    

    return filtered
}

export const isDevEnv = () => (
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("uat")
);

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const fireUnauthorizedActionEvent = (data) => {
    Emitter.emit('UNAUTHORIZED_ACTION', data)
}
export const fireCompanyNotAddedEvent = (data) => {
    Emitter.emit('COMPANY_NOT_ADDED', data)
}

export const fireBidOfferSentEvent = (data) => {
    Emitter.emit('BID_OFFER_SENT', data)
}

export const fireAdCreditQTYListingBuySellNow = (data) => {
    Emitter.emit('AD_CREDIT_QTY_BUY_SELL_NOW', data)
}

export const flagImgSrc = (flag_img, origin_of_coal) => {
    if (!flag_img || flag_img == "undefined") {
        if (origin_of_coal == 1)
            return "/images/flags/India.png"

        return "/images/flags/noflag.png"
    }

    return flag_img
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const stringAvatar = (name) => {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(" ")[0][0]}`,
	};
};

export const uniqueBy = (arr, key) => {
	const seen = new Set();
	return arr.filter((item) => {
		const k = item[key];
		return seen.has(k) ? false : seen.add(k);
	});
};

