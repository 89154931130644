import resource from 'resource-axios';
import axios from '../baseCommodity';
import { config } from '../../config';


export const CommodityUser = resource(config.common_api_commodity_url, {



    addCompnay: (params) => {
        return axios.post(`${config.common_api_commodity_url}/user/companies/add`, params)
    },

    getAddress: (params) => {
        return axios.get(`${config.common_api_commodity_url}/user/companies/${params}/addresses`)
    },
    addAddress: (params) => {
        return axios.post(`${config.common_api_commodity_url}/user/companies/add-address`,params)
    },

    otpverification: (params) => {

        return axios.post(`${config.common_api_commodity_url}/otpverification/verify`, params)
    },
     // switch company
    switchCompany:(params)=>{
        return axios.patch(`${config.common_api_commodity_url}/user/companies/switch/${params}`);
    },

   otpSend: (params) => {


    return axios.post(`${config.common_api_commodity_url}/otpverification/send`, params)
   },
   fetchMarket: (params) => {
         // console.log('mydata',params)
         let queryString = "";
         if(typeof params == 'object')
         {
             queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
         }
         return axios.get(`${config.common_api_commodity_url}/markets`)

    // return axios.post(`${config.common_api_commodity_url}/markets`, params)
   },

   userMaket: (params) => {


    return axios.get(`${config.common_api_commodity_url}/user/markets`)


   },
   userCommodities:(params)=>{

    return axios.get(`${config.common_api_commodity_url}/user/commodities`)
},

   Contracts: (params) => {
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    return axios.get(`${config.common_api_commodity_url}/contracts?${queryString}`)


   },
    userContracts: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.common_api_commodity_url}/user/contracts?${queryString}`)
    },
   fetchListings: (params) => {
    // console.log('mydata',params)
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user/listings?${queryString}`)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
latestListings: (params) => {
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user/listings/contract-latest-listing?${queryString}`)
},
expiryValues: (params) => {
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    return axios.get(`${config.common_api_commodity_url}/user/listings/expiry-values?${queryString}`)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
CreateListing: (params) => {
    // console.log('mydata',params)
    return axios.post(`${config.common_api_commodity_url}/user/listings/create`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
updateListing: (params, id) => {
    return axios.put(`${config.common_api_commodity_url}/user/listings/update/${id}`, params)
},
FetchAllListings: (params) => {
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user/listings`)

},
sendChat: (params) => {
    console.log({paramsSend:params})
    return axios.post(`${config.common_api_commodity_url}/user/chats/send-message`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
bestBuyers: (contractId, params) => {

    console.log('mydata buyer',params, {contractId})
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/listings/contract/${contractId}/live-buyers?${queryString}`)
    // return axios.post(`${config.common_api_commodity_url}64edeafee3cda1e6bb156d20`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
bestSeller: (contractId, params) => {
    console.log('mydata seller',params, {contractId})
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/listings/contract/${contractId}/live-sellers?${queryString}`)
    // return axios.post(`${config.common_api_commodity_url}64edeafee3cda1e6bb156d20`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},

fetchChatMessages: (chatId) => {
    // console.log(chatId)

let queryString = "";
let params={}
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user/chats/${chatId}/fetch-messages?${queryString}`)

},

storeMarkets: (params) => {
    // console.log('mydata',params)
    return axios.patch(`${config.common_api_commodity_url}/user/markets/select`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},storeCommodities: (params) => {
    // console.log('mydata',params)
    return axios.patch(`${config.common_api_commodity_url}/user/profile/select-commodities`, params)
    // return axios.post(`${config.common_api_commodity_url}}/listings/create`params)

// return axios.post(`${config.common_api_commodity_url}/markets`, params)
},
expireListing:(params) =>{
    return axios.patch(`${config.common_api_commodity_url}/user/listings/expire`,params)

},
chatYesNo:(communicationId) =>{
    // console.log(communicationId)

    return axios.patch(`${config.common_api_commodity_url}/user/chats/communications/${communicationId.id}/yes`)

},
dealRejectReason:(params)=>{
    // console.log(params)
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user/listings/reject-reasons?${queryString}`)

},
checkAndLoadUser:()=>{
    return axios.get(`${config.common_api_commodity_url}/user/profile`)
},
latestDeals:()=>{
    return axios.get(`${config.common_api_commodity_url}/deals/latest-deals`)
},
// top upto 5 contracts in which most listings are within 7 days
activeContracts:()=>{
    return axios.get(`${config.common_api_commodity_url}/contracts/active`)
},
favouriteContracts:()=>{
    return axios.get(`${config.common_api_commodity_url}/user/contracts/fav`)
},
getDeals:()=>{
    return axios.get(`${config.common_api_commodity_url}/user/deals`)
},

getActiveListingCount: (params) => {
    let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
    return axios.get(`${config.common_api_commodity_url}/user/listings?${params}`)
},

getFavouriteContractListings : () =>  {
    return axios.get(`${config.common_api_commodity_url}/user/listings/favourite-contracts`)
},
addUserPoc: (params) => {
    return axios.post(`${config.common_api_commodity_url}/user-poc/add`, params)
},
fetchUserPocs: (params) => {
    let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    return axios.get(`${config.common_api_commodity_url}/user-poc?${queryString}`)
},
deleteProfile:()=>{
    return axios.patch(`${config.common_api_commodity_url}/user/profile/delete-account`)
},

}, axios)