import {
  InputLabel,
  Grid,
  Autocomplete,
  Select,
  MenuItem,
  Button,
  TextField,
  Container,
  Typography,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { useRef } from 'react';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { CommodityCommon } from '../../api/resources/admin-api/CommodityCommon';
import GstCheck from './GstCheck';

const AddNewUser = (props) => {
  const formRef = useRef(null);
  const [selectedMarketType, setSelectedMarketType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [industryArray, setindustryArray] = useState([]);
  const [commoditiesArray, setcommoditiesArray] = useState([]);
  const [commodityTypeArray, setcommodityTypeArray] = useState([]);
  const [marketsArray, setmarketsArray] = useState([]);
  const [selectedCommodityType, setSelectedCommodityType] = useState([]);
  const [SelectedCommodity, setSelectedCommodity] = useState([]);
const [gstCheckErrorMessage, setGstCheckErrorMessage] = React.useState(true)
const [errorMessage, setErrorMessage] = React.useState('')

  const handleCommodityTypeChange = (e, event) => {
    console.log('commodity',event)
    setSelectedCommodityType(event);
  };
  // ============= adding all commodities to the list if select all is selected ==============
  const handleSelectedType = (e, event) => {
    // console.log({event})
    // const eventAll = event.filter(e => e.name === 'all');
    // console.log('ev', event[0])
    if(event[0]._id === 'all'){
      console.log('all event')
      setSelectedCommodity(commoditiesArray.slice(1)) 
      props.onChangeComm({
        event:commoditiesArray.slice(1)
      }); 
    } else {
      console.log('else event')
      setSelectedCommodity(event);
      props.onChangeComm({
        event
      });
    }
    // const onChangeComm = event.filter(e => e._id !== 'all')
    // console.log({onChangeComm})
    // props.onChangeComm({
    //   event
    // });
  };
  const handleMarketType = (e, event) => {
    // console.log(event)
    setSelectedMarketType(event);
    props.onChangeMark({
      event,
    });
  };
  const handleIndustryTypeChange = (e, event) => {
    //  console.log(event)
    setSelectedIndustry(event);
    props.onChangeIndus({
      event,
    });
    // const submitForm=()={

    // }
  };
  React.useEffect(() => {
    getindustry();
    getcommodityTypes();
    getmarkets();
  }, []);
  React.useEffect(() => {
    getcommodities();
  }, [selectedCommodityType]);

  const getindustry = async () => {
    // var param = Object.assign({ page: page + 1, limit: 1000 }, param);
    const res = await CommodityCommon.Industry();

    try {
      if (res.status == 200) {
          //  console.log('op',res.data.data[0].result)
          //  console.log('op2',res);
        setindustryArray(res.data.data[0].result);
      }
    } catch (error) {
      console.log('error in get commodity type', error);
    }
  };
  const getcommodities = async () => {
    // var param = Object.assign({ page: page + 1, limit: 1000 }, param);
    let param = {}
        Object.assign(param, {"search[commodityTypeId]":selectedCommodityType[0]._id})
    const res = await CommodityCommon.Commodities(param);
    try {
      if (res.status == 200) {
          //  console.log('select commodities',res.data.data)
        const commArray = res.data.data;
        const commoditiesArrayWithSelectAll = [{_id: 'all', name: "select all"}, ...commArray];
        setcommoditiesArray(commoditiesArrayWithSelectAll)
      }
    } catch (error) {
      console.log('error in get commodity type', error);
    }
  };
  const getcommodityTypes = async () => {
    // var param = Object.assign({ page: page + 1, limit: 1000 }, param);
    const res = await CommodityCommon.CommodityType();

    try {
      if (res.status == 200) {
           console.log('commodities',res.data.data)

        setcommodityTypeArray(res.data.data);
      }
    } catch (error) {
      console.log('error in get commodity type', error);
    }
  };
  const getmarkets = async () => {
    // var param = Object.assign({ page: page + 1, limit: 1000 }, param);
    const res = await CommodityCommon.AllMarkets();

    try {
      if (res.status == 200) {
        //   console.log(res.data.data)

        setmarketsArray(res.data.data);
      }
    } catch (error) {
      console.log('error in get commodity type', error);
    }
  };

  return (
    <Box className="signUpForm form-ui" py={3}>
      <ValidatorForm
        ref={formRef}
        onSubmit={(e) =>  props.onSubmit(e)}
        //   model={defaultValues}
      >
        <Grid container spacing={2}>

       <Grid item sm={12} xs={12}>
            <InputLabel sx={{ fontSize: 12, fontWeight: 'bold' }}>
              Select Your Industry<sup style={{ color: 'red' }}>*</sup>
            </InputLabel>
            <Autocomplete
              name="industry"
              size="small"
              options={industryArray}
              // onChange={(e, industry) => {
              //     setSelectedIndustry(industry);

              //   }}
              onChange={handleIndustryTypeChange}
              getOptionLabel={(industry) =>
                industry.name ? industry.name : ''
              }
              value={selectedIndustry}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  variant="outlined"
                  required
                  fullWidth
                  validators={['required']}
                  errorMessages={['Industry field is required']}
                  value={selectedIndustry}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <InputLabel sx={{ fontSize: 12, fontWeight: 'bold' }}>
              Commodity Type<sup style={{ color: 'red' }}>*</sup>
            </InputLabel>
            <Autocomplete
              name="commodity_types"
              multiple
              size="small"
              options={commodityTypeArray}
              onChange={handleCommodityTypeChange}
              value={selectedCommodityType}
              getOptionLabel={(commodity) =>
                commodity.name ? commodity.name : ''
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  variant="outlined"
                  required
                  fullWidth
                  validators={['required']}
                  errorMessages={['Commodity type field is required']}
                  value={selectedCommodityType}
                />
              )}
            />
          </Grid>

         
              <Grid item sm={12} xs={12} style={{marginBottom:5}}>
                <InputLabel sx={{ fontSize: 12, fontWeight: 'bold' }}>
                  Select Commodities<sup style={{ color: 'red' }}>*</sup>
                </InputLabel>
                <Autocomplete
                  name={`commodities`}
                  multiple
                  size="small"
                  options={commoditiesArray}
                  onChange={handleSelectedType}
                  value={SelectedCommodity}
                  getOptionLabel={(commodity) =>
                    commodity.name ? commodity.name : ''
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant="outlined"
                      required
                      fullWidth
                      validators={['required']}
                      errorMessages={ 'field is required'}
                      value={SelectedCommodity}
                    />
                  )}
                />
              </Grid>
            {/* <Button onClick={()=>console.log(SelectedCommodity)}>selectedComms</Button> */}
        
          
          {/* {selectedCommodityType.length > 0 ? (
            <Grid item sm={12} xs={12}>
              <InputLabel sx={{ fontSize: 12, fontWeight: 'bold' }}>
                Select Market Type<sup style={{ color: 'red' }}>*</sup>
              </InputLabel>
              <Autocomplete
                name="markets"
                multiple
                size="small"
                options={marketsArray}
                onChange={handleMarketType}
                value={selectedMarketType}
                getOptionLabel={(market) => (market.name ? market.name : '')}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="outlined"
                    required
                    fullWidth
                    validators={['required']}
                    errorMessages={['Markets field is required']}
                    value={selectedMarketType}
                  />
                )}
              />
            </Grid>
          ) : (
            <></>
          )} */}
       </Grid>
       <Grid item sm={12}>
    <GstCheck setGstCheckErrorMessage={setGstCheckErrorMessage} />
    {gstCheckErrorMessage && errorMessage && (
        <p style={{ color: "red", textAlign: "center" }}>GST information required</p>
    )}
</Grid>

       <Grid item sm={12}  >
         
            <Button
              type="button"
              style={{
                color: '#FFF',
                backgroundColor: '#2A59B7',
                marginTop: 5,
              
                width:"100%"
              }}
              onClick={(e)=>{ if(gstCheckErrorMessage){
                setErrorMessage(true)
                return
              }else{formRef.current.submit(e)}}}
            >
              Submit
            </Button>
          
        </Grid>
  

       
      </ValidatorForm>
    </Box>
  );
  // return (
  //     <>
  //             <ValidatorForm>

  //                  </ValidatorForm>
  //     </>
  // );
};

export default AddNewUser;
